<template>
  <a-modal
    title="角色部门"
    :width="780"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="部门" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-tree-select
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="departmentTreeData"
            v-decorator="[
              'department_id',
              {
                rules: [
                  { required: false, message: '' },
                ],
              },
            ]"
            placeholder="请选择部门"
          >
          </a-tree-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
// 表单字段
import { oaDepartmentList } from '@/api/oa_department'
import pick from 'lodash.pick'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      departmentTreeData: [],
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this)
    }
  },
  mounted () {
    this.loadEditInfo(this.model)
  },
  methods: {
    handleDepartmentSearch (value) {
      oaDepartmentList({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.departmentList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleDepartmentChange (value) {
      if (value === undefined) {
        this.handleDepartmentSearch(value)
      }
    },
    loadEditInfo (data) {
      oaDepartmentList({ page: 1, page_size: 99999 }).then((res) => {
        const result = res.data.entries || []
        this.departmentTreeData = this.buildTree(this.transformDepartments(result))
      }).finally(() => {
        const { form } = this
        new Promise((resolve) => {
          setTimeout(resolve, 0)
        }).then(() => {
          const formData = pick(data, ['id', 'department_id'])
          this.id = formData.id
          form.setFieldsValue(formData)
        })
      })
    },
    transformDepartments (departments) {
      return departments.map(department => ({
        title: department.name,
        value: department.id,
        key: department.id,
        parent_department_id: department.parent_department_id,
        remark: department.remark
      }))
    },
    buildTree (departments) {
      const idToNodeMap = {}
      const tree = []
      // 初始化每个节点
      departments.forEach(department => {
        idToNodeMap[department.key] = { ...department, children: [] }
      })
      // 构建树状结构
      departments.forEach(department => {
        const parentId = department.parent_department_id
        if (parentId === -1) {
          tree.push(idToNodeMap[department.key])
        } else {
          idToNodeMap[parentId].children.push(idToNodeMap[department.key])
        }
      })
      return tree
    }
  }
}
</script>
