<template>
  <a-modal
    title="新建角色"
    :width="780"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="角色名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            v-decorator="['name', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]"
            placeholder="角色名称"/>
        </a-form-item>
        <a-form-item label="部门" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-tree-select
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="departmentTreeData"
            v-decorator="[
              'department_id',
              {
                rules: [
                  { required: true, message: '' },
                ],
              },
            ]"
            placeholder="请选择部门"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item label="分类" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            option-label-prop="tt"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            show-search
            allow-clear
            v-decorator="[
              'category_id',
              {
                rules: [
                  { required: false, message: '' },
                ],
              },
            ]"
            placeholder="分类名称"
            :default-active-first-option="false"
            @search="this.categorySearch"
            @change="handleCategoryChange"
          >
            <a-select-option v-for="d in categoryList" :key="d.id" :tt="d.name">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
// 表单字段
import debounce from 'lodash/debounce'
import { oaDepartmentList } from '@/api/oa_department'
import { oaRoleCategoryList } from '@/api/oa_role_category'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.departmentSearch = debounce(this.handleDepartmentSearch, 800)
    this.categorySearch = debounce(this.handleCategorySearch, 800)
    return {
      departmentTreeData: [],
      categoryList: [],
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this)
    }
  },
  created () {
    this.handleDepartmentSearch()
  },
  methods: {
    handleDepartmentSearch () {
      oaDepartmentList().then((res) => {
        const result = res.data.entries || []
        this.departmentTreeData = this.buildTree(this.transformDepartments(result))
      })
    },
    transformDepartments (departments) {
      return departments.map(department => ({
        title: department.name,
        value: department.id,
        key: department.id,
        parent_department_id: department.parent_department_id,
        remark: department.remark
      }))
    },
    buildTree (departments) {
      const idToNodeMap = {}
      const tree = []
      // 初始化每个节点
      departments.forEach(department => {
        idToNodeMap[department.key] = { ...department, children: [] }
      })
      // 构建树状结构
      departments.forEach(department => {
        const parentId = department.parent_department_id
        if (parentId === -1) {
          tree.push(idToNodeMap[department.key])
        } else {
          idToNodeMap[parentId].children.push(idToNodeMap[department.key])
        }
      })
      return tree
    },
    handleCategorySearch (value) {
      oaRoleCategoryList({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.categoryList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleCategoryChange (value) {
      if (value === undefined) {
        this.handleCategorySearch(value)
      }
    }
  }
}
</script>
