var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建角色","width":780,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-form-item',{attrs:{"label":"角色名称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]),expression:"['name', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]"}],attrs:{"placeholder":"角色名称"}})],1),_c('a-form-item',{attrs:{"label":"部门","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'department_id',
            {
              rules: [
                { required: true, message: '' },
              ],
            },
          ]),expression:"[\n            'department_id',\n            {\n              rules: [\n                { required: true, message: '' },\n              ],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"tree-data":_vm.departmentTreeData,"placeholder":"请选择部门"}})],1),_c('a-form-item',{attrs:{"label":"分类","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'category_id',
            {
              rules: [
                { required: false, message: '' },
              ],
            },
          ]),expression:"[\n            'category_id',\n            {\n              rules: [\n                { required: false, message: '' },\n              ],\n            },\n          ]"}],attrs:{"option-label-prop":"tt","show-arrow":false,"filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"分类名称","default-active-first-option":false},on:{"search":this.categorySearch,"change":_vm.handleCategoryChange}},_vm._l((_vm.categoryList),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }