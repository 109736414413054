<template>
  <a-modal
    title="角色分类"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="分类" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            option-label-prop="tt"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            show-search
            allow-clear
            v-decorator="[
              'category_id',
              {
                rules: [
                  { required: true, message: '' },
                ],
              },
            ]"
            placeholder="请输入分类名称"
            :default-active-first-option="false"
            @search="this.categorySearch"
            @change="handleCategoryChange"
          >
            <a-select-option v-for="d in categoryList" :key="d.id" :tt="d.name">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import debounce from 'lodash/debounce'
import { oaRoleCategoryList } from '@/api/oa_role_category'
import pick from 'lodash.pick'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.categorySearch = debounce(this.handleCategorySearch, 800)
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      categoryList: []
    }
  },
  mounted () {
    this.loadEditInfo(this.model)
  },
  methods: {
    loadEditInfo (data) {
      if (data.category_id !== -1) {
        oaRoleCategoryList({ id: data.category_id }).then((res) => {
          this.categoryList = res.data.entries || []
        }).finally(() => {
          const { form } = this
          new Promise((resolve) => {
            setTimeout(resolve, 0)
          }).then(() => {
            const formData = pick(data, ['id', 'category_id'])
            this.id = formData.id
            form.setFieldsValue(formData)
          })
        })
      }
    },
    handleCategorySearch (value) {
      oaRoleCategoryList({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.categoryList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleCategoryChange (value) {
      if (value === undefined) {
        this.handleCategorySearch(value)
      }
    }
  }
}
</script>
