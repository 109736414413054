import request from '@/utils/request'

const oaDepartmentApi = {
  oaDepartmentList: '/oa/department/',
  oaDepartmentCreate: '/oa/department/',
  oaDepartmentUpdate: '/oa/department/',
  oaDepartmentDelete: '/oa/department/'
}

/**
 * 列表
 */
export function oaDepartmentList (parameter) {
  return request({
    url: oaDepartmentApi.oaDepartmentList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function oaDepartmentCreate (parameter) {
  return request({
    url: oaDepartmentApi.oaDepartmentCreate,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function oaDepartmentUpdate (parameter, oa_department_id) {
  return request({
    url: oaDepartmentApi.oaDepartmentUpdate + oa_department_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function oaDepartmentDelete (oa_department_id) {
  return request({
    url: oaDepartmentApi.oaDepartmentDelete + oa_department_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
