import request from '@/utils/request'

const oaRoleApi = {
  oaRoleList: '/oa/role/',
  oaRoleCreate: '/oa/role/',
  oaRoleUpdate: '/oa/role/',
  oaRoleDelete: '/oa/role/'
}

/**
 * 列表
 */
export function oaRoleList (parameter) {
  return request({
    url: oaRoleApi.oaRoleList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function oaRoleCreate (parameter) {
  return request({
    url: oaRoleApi.oaRoleCreate,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function oaRoleUpdate (parameter, oa_role_id) {
  return request({
    url: oaRoleApi.oaRoleUpdate + oa_role_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function oaRoleDelete (oa_role_id) {
  return request({
    url: oaRoleApi.oaRoleDelete + oa_role_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
